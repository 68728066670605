import {BloodStation} from "./blood_station";
import {ChoicesWithIconType} from "./common";

export type DonationStatus =
  | 'waiting'
  | 'accepted'
  | 'rejected'
  | 'without_image'
  | 'deleted'
  | "waiting_after_accept"
  | "pre_accepted";

export type DonationStatusType = {
  [s in DonationStatus]: ChoicesWithIconType;
}

export interface Donation {
  id: number | undefined
  status: DonationStatus | undefined
  donate_at: string | undefined
  blood_class: string | undefined
  payment_type: string | undefined
  volume: number | undefined
  payment_cost: number | undefined
  image: string | undefined
  user: number | undefined
  blood_station: BloodStation | undefined
  blood_station_id: number | undefined
  plan_date: string | undefined
  email: string | undefined
  is_out: boolean | undefined
  image_id: boolean | undefined
  city_id: number | undefined
}

export const DONATION_STATUSES: DonationStatusType = {
  waiting: {title: 'На модерации', icon: '/static/images/icons/waitStatus.svg', color: "#F7C41C", textColor: "#C1C4CA"},
  waiting_after_accept: {title: 'На модерации', icon: '/static/images/icons/waitStatus.svg', color: "#F7C41C", textColor: "#C1C4CA"},
  accepted: {title: 'Подтверждена', icon: '/static/images/icons/acceptStatus.svg', color: "#1FC180", textColor: "#1FC180"},
  rejected: {title: 'Не принята', icon: '/static/images/icons/declineStatus.svg', color: "#EF0000", textColor: "#EF0000"},
  deleted: {title: 'Удалена', icon: '/static/images/icons/declineStatus.svg', color: "#EF0000", textColor: "#EF0000"},
  without_image: {title: 'Без справки', icon: '/static/images/icons/waitStatus.svg', color: "#C1C4CA", textColor: "#C1C4CA"},
  pre_accepted: {title: 'Подтверждена', icon: '/static/images/icons/acceptStatus.svg', color: "#1FC180", textColor: "#1FC180"},
};

export interface DonationAgg {
  count: number
  last_donation_at: string,
  first_donation_at: string,
  blood: number
  plasma: number
  platelets: number
  erythrocytes: number
  leukocytes: number
  unconfirmed_donations: number
  count_year: number
}

